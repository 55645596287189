import React, { Component } from "react";
import quizQuestions from "./api/fonts";
import Quiz from "./components/Quiz";
import Result from "./components/Result";
import PlayerName from "./components/playerName";
import SuggestForm from "./components/Suggest";


import "./App.css";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      counter: 0,
      questionId: 1,
      question: "",
      questionAnswer: "",
      fontURL: "",
      answerOptions: [],
      answer: "",
      answersCount: {},
      result: "",
      score: 0,
      highScore: 0,
      playerName: "",
      suggestModal: false,
    };

    this.handleAnswerSelected = this.handleAnswerSelected.bind(this);
  }

  componentDidMount() {
    const shuffledAnswerOptions = quizQuestions.map((question) =>
      this.shuffleArray(question.answers)
    );
    this.setState({
      question: quizQuestions[0].question,
      answerOptions: shuffledAnswerOptions[0],
      questionAnswer: quizQuestions[0].questionAnswer,
      fontURL: quizQuestions[0].fontURL,
    });
  }

  shuffleArray(array) {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  handleAnswerSelected(event) {
    this.setUserAnswer(event.currentTarget.value);
    if (this.state.questionId < quizQuestions.length) {
      setTimeout(() => this.setNextQuestion(), 500);
    } else {
      setTimeout(() => this.setResults(this.getResults()), 500);
    }
  }

  setUserAnswer(answer) {
    this.setState((state, props) => ({
      answersCount: {
        ...state.answersCount,
        [answer]: (state.answersCount[answer] || 0) + 1,
      },
      answer: answer,
    }));
  }

  setNextQuestion() {
    const counter = this.state.counter + 1;
    const questionId = this.state.questionId + 1;

    const validated = this.state.answer === this.state.questionAnswer;
    const scoreIncrease = this.state.score + 100;
    const scoreDecrase = this.state.score < 1 ? 0 : this.state.score - 50;
    const score = validated ? scoreIncrease : scoreDecrase;

    this.setState({
      counter: counter,
      questionId: questionId,
      question: quizQuestions[counter].question,
      answerOptions: quizQuestions[counter].answers,
      answer: "",
      questionAnswer: quizQuestions[counter].questionAnswer,
      fontURL: quizQuestions[counter].fontURL,
      score: score,
    });
  }
  getResults() {
    const answersCount = this.state.answersCount;
    const answersCountKeys = Object.keys(answersCount);
    const answersCountValues = answersCountKeys.map((key) => answersCount[key]);
    const maxAnswerCount = Math.max.apply(null, answersCountValues);

    return answersCountKeys.filter(
      (key) => answersCount[key] === maxAnswerCount
    );
  }

  setResults(result) {
    if (result.length === 1) {
      this.setState({ result: result[0] });
    } else {
      this.setState({ result: "Undetermined" });
    }
  }

  renderQuiz() {
    return (
      <>
        <PlayerName score={this.state.score} />
        <Quiz
          answer={this.state.answer}
          questionAnswer={this.state.questionAnswer}
          answerOptions={this.state.answerOptions}
          questionId={this.state.questionId}
          question={this.state.question}
          fontURL={this.state.fontURL}
          questionTotal={quizQuestions.length}
          onAnswerSelected={this.handleAnswerSelected}
        />
        <SuggestForm />
 
        <div className="m-auto mt-5 text-center fixed">
          {this.state.answer ? (
            this.state.answer === this.state.questionAnswer ? (
              <button className="bg-green-200 text-green-600 text-base font-semibold px-6 py-2 rounded-lg">
                Correct 👍
              </button>
            ) : (
              <button className="bg-red-200 text-red-700 text-base font-semibold px-6 py-2 rounded-lg">
                Wrong
              </button>
            )
          ) : null}
        </div>
      </>
    );
  }

  render() {
    return (
      <div className="container mx-auto h-screen flex flex-col justify-center items-center">
        {this.state.result ? this.renderResult() : this.renderQuiz()}
      </div>
    );
  }
  renderResult() {
    return <Result quizResult={this.state.score} />;
  }
}

export default App;
