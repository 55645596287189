import React from "react";
import PropTypes from "prop-types";

function AnswerOption(props) {
  return (
    <li className="card-items">
      <input
        type="radio"
        className="hidden"
        name="radioGroup"
        checked={props.answerType === props.answer}
        id={props.answerType}
        value={props.answerType}
        disabled={props.answer}
        onChange={props.onAnswerSelected}
      />
      <label className="cursor-pointer" htmlFor={props.answerType}>
        <div className="bg-white hover:shadow border w-full flex items-center p-2 rounded-xl ">
          <div className="flex-grow p-3 ">
            <div className="text-sm text-gray-500">
              <img
                className="m-auto w-60 md:w-80"
                src={props.answerContent}
                alt="Almost before we knew it, we had left the ground. 🎉"
              />
            </div>
          </div>
        </div>
      </label>
    </li >
  );
}

AnswerOption.propTypes = {
  answerType: PropTypes.string.isRequired,
  answerContent: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  onAnswerSelected: PropTypes.func.isRequired,
  questionAnswer: PropTypes.string,
};

export default AnswerOption;




