import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import Header from "../src/components/Header";
import Footer from "../src/components/Footer";
// import Notification from "../src/components/Notification";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Changelog from "./pages/changelog";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      {/* <Notification /> */}
      <Header />
      <Switch>
        <Route path="/changelog">
          <Changelog />
        </Route>
        <Route path="/">
          <App />
        </Route>
      </Switch>
      <Footer />
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
