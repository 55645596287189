import React, { useState } from "react";

const PlayerName = (props) => {
  const [state, setState] = useState({});
  const { name } = useState({});

  const [modal, setModal] = useState(true);

  function handleSubmit(e) {
    e.preventDefault();
    if (!modal) setModal(true);
    else setModal(false);
  }

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  return (
    <>
      <div className="mt-12 text-sm lg:text-base flex align-center items-center font-normal fixed top-8 right-5 bg-gray-900 text-gray-100 p-2 rounded player-name">
        {state.name}
        <div className="flex rounded-full px-2 py-0.5  sm:flex xl:flex items-center space-x-1">
          <dt className="text-gray-500">
            <svg width="16" height="20" fill="yellow">
              <path d="M7.05 3.691c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.372 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.539 1.118l-2.8-2.034a1 1 0 00-1.176 0l-2.8 2.034c-.783.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.363-1.118L.98 9.483c-.784-.57-.381-1.81.587-1.81H5.03a1 1 0 00.95-.69L7.05 3.69z" />
            </svg>
          </dt>
          <dd className="font-bold text-sm lg:text-base">{props.score}</dd>
        </div>
      </div>
      <div
        className="fixed z-10 inset-0 overflow-y-auto over"
        style={{
          display: modal === false ? "none" : "",
        }}
      >
        <form onSubmit={handleSubmit}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75" />
            </div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            ></span>
            <div
              className="
              inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full 
              modal-dialog
              "
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                    <h2
                      className="text-lg leading-6 font-medium text-gray-900"
                      id="modal-headline"
                    >
                      Player Name
                    </h2>
                    <div className="mt-2 w-full">
                      <input
                        required
                        onChange={handleChange}
                        type="text"
                        name="name"
                        value={name}
                        maxLength={15}
                        className="w-full rounded p-2 focus:outline-none border-2 focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="submit"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-900 text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Play
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
export default PlayerName;
