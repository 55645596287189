import React from "react";
import PropTypes from "prop-types";

function QuestionCount(props) {
  return (
    <div className="text-sm">
      {props.counter < 12 ? (
        <span className="text-bold text-xs">Level 1</span>
      ) : (
        <span className="text-bold text-xs">Level 2</span>
      )}

      {/* <span>{props.counter}</span> of <span>{props.total}</span> */}
      <div className="mb-1 mt-2 lg:mb-5 bg-gray-200 rounded-full">
        {props.counter < 12 ? (
          <div
            className={`w-${props.counter}/12 mt-2 bg-gray-400 py-1 rounded-full`}
          />
        ) : (
          <div
            className={`w-${
              props.counter - 12
            }/12 mt-2 bg-gray-600 py-1 rounded-full`}
          />
        )}
      </div>
    </div>
  );
}

QuestionCount.propTypes = {
  counter: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

export default QuestionCount;
