import React from "react";
import PropTypes from "prop-types";

function Result(props) {
  const shareTitle = `A font game for designers! 🎲 My score is ${props.quizResult}`;
  return (
    <div className="m-auto text-center">
      <div className="rounded-full bg-amber-50 text-amber-900 px-2 py-0.5 sm:flex  xl:flex items-center space-x-1 m-auto grid">
        {/* <dt className="text-gray-500">
          <span className="sr-only text-gray-500 "></span>
          <svg width="16" height="20" fill="black">
            <path d="M7.05 3.691c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.372 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.539 1.118l-2.8-2.034a1 1 0 00-1.176 0l-2.8 2.034c-.783.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.363-1.118L.98 9.483c-.784-.57-.381-1.81.587-1.81H5.03a1 1 0 00.95-.69L7.05 3.69z" />
          </svg>
        </dt> */}
         <div className="m-auto text-center">
        Your score is <br />
        <br/>
        <dd className="text-6xl font-bold"> {props.quizResult}</dd>
        </div>
      </div>
      <br />
      <div className="m-auto text-center">
        <a href="/">
          <button className="mt-10 bg-gray-900 text-gray-100 text-base font-semibold px-6 py-2 rounded-lg">
            Restart
          </button>
        </a>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={`https://twitter.com/intent/tweet?text=${shareTitle} ${"https://foont.co"}`}
        >
          <button className="mt-10 ml-2 bg-gray-300 text-gray-900 text-base font-semibold px-6 py-2 rounded-lg">
            <svg
              width="20"
              className='inline mr-2'
              height="20"
              viewBox="0 0 48 39"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M48 4.62611C46.2348 5.40786 44.3386 5.93608 42.3451 6.17483C44.402 4.94471 45.9409 3.00866 46.6745 0.727914C44.742 1.87517 42.627 2.68271 40.4214 3.11543C38.9382 1.53262 36.9736 0.483513 34.8327 0.13098C32.6918 -0.221552 30.4943 0.142215 28.5815 1.16581C26.6686 2.1894 25.1474 3.81554 24.254 5.79178C23.3606 7.76801 23.145 9.98376 23.6406 12.095C19.7249 11.8985 15.8942 10.8813 12.3973 9.10937C8.90032 7.33744 5.81522 4.85041 3.3422 1.80969C2.49661 3.26755 2.01039 4.95783 2.01039 6.75797C2.00945 8.37851 2.40874 9.97423 3.17283 11.4036C3.93691 12.8329 5.04218 14.0516 6.39056 14.9516C4.8268 14.9019 3.29754 14.4795 1.93006 13.7198V13.8466C1.9299 16.1194 2.71653 18.3224 4.15647 20.0816C5.59641 21.8408 7.60096 23.0479 9.83 23.498C8.37935 23.8904 6.85846 23.9482 5.38219 23.6671C6.01109 25.6227 7.23614 27.3329 8.88583 28.5581C10.5355 29.7834 12.5273 30.4624 14.5822 30.5C11.0938 33.237 6.78563 34.7217 2.35074 34.7151C1.56515 34.7153 0.780219 34.6695 0 34.5778C4.50167 37.4707 9.74193 39.0059 15.0938 39C33.2106 39 43.1146 24.003 43.1146 10.9963C43.1146 10.5738 43.104 10.147 43.085 9.72441C45.0115 8.33199 46.6743 6.60774 47.9958 4.63245L48 4.62611V4.62611Z"
                fill="#0F0F0F"
              />
            </svg>
            Share on Twitter
          </button>
        </a>
      </div>
    </div>
  );
}

Result.propTypes = {
  quizResult: PropTypes.string.isRequired,
};

export default Result;
