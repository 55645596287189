import React from "react";
import ReactMarkdown from "react-markdown";

const Changelog = () => {
 const md =  `
## 1.0.2
- Added new additional fonts.
- Fixed sharing score on Twitter.

## 1.0.1
- Added new additional fonts.
 - Avenir  
 - Comic Neue 
 - Comic Sans 
 - Fira Sans
 - Helvetica 
 - Lato
 - Lexend Deca 
 - Sensation 
 - Montserrat 
 - Raleway 
 - Added Level Sections
`
  return (
    <div className="container changelog-main">
      <div className="content">
      <ReactMarkdown source={md} />
        {/* <h2 className="font-bold text-2xl">Changelog</h2>
        <br />
        <h3 className="font-bold text-lg mb-2">Version: 1.0.1</h3>
        <h3 className="text-md">Added new additional fonts! 🎉 </h3>
        <ul className="">
          {" "}
          <li> Helvetica</li> <li> Comic Neue </li> <li> Comic Sans </li>{" "}
          <li> Fira Sans</li> <li> Lato</li> <li> Avenir </li>{" "}
          <li> Lexend Deca </li> <li> Sensation </li> <li> Montserrat </li>{" "}
          <li> Raleway </li>{" "}
        </ul> */}
      </div>
    </div>
  );
};

export default Changelog;
