import React from "react";
import PropTypes from "prop-types";

const Header = (props) => {
  return (
    <header className="p-2 flex lg:p-4 align-center fixed logo">
      <a href="/" title="Foont.co">
        <h1 className="text-4xl m-auto md:text-6xl font-bold">
          Foont<span className="font-normal">.co</span>
        </h1>
        <text className="text-xs text-gray-600">1.0.2</text>
      </a>
    </header>
  );
};

Header.propTypes = {
  PlayerName: PropTypes.string.isRequired,
};
export default Header;
