import React from "react";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <footer className="w-auto p-5 text-gray-200 flex justify-center fixed bottom-1 left-5 text-xs text-gray-600 footer">
      <div>
        {" "}
        About me{" "}
        <a
          rel="noopener noreferrer"
          target="_blank"
          className="font-bold"
          href="https://raufsamestone.com"
        >
          {" "}
          raufsamestone.com 👋
        </a>
        <br />
        Inspired by{" "}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://cantunsee.space"
          className="font-bold"
        >
          cantunsee.space
        </a>{" "}
        <br />
        <Link
          className="text-xs text-gray-600"
          to="/changelog"
          title="Changelog"
        >
          Changelog
        </Link> 
        <br />
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://support.google.com/analytics/answer/2700409?hl=en&utm_id=ad"
        >
          Foont.co use cookies! 🍪
        </a>
      </div>
      <div>
        {/* <a
            href="https://www.producthunt.com/posts/foont?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-foont"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=288931&theme=light&period=daily"
              alt="Foont - A game for designers to identify fonts | Product Hunt"
              className="badge"
              width={250}
              height={54}
            />
          </a> */}
      </div>
    </footer>
  );
};

export default Footer;
