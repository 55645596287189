var fonts = [
  {
    question: "Which one is the **Roboto** ?",
    questionAnswer: "Roboto",
    fontURL: 'https://fonts.google.com/specimen/Roboto',
    answers: [
      {
        font: "Roboto",
        content:
          "https://res.cloudinary.com/raufsamestone/image/upload/v1616094119/foontus/yzcoikyusvwbuxz1alrb.png",
      },
      {
        font: "Karla Tamil",
        content:
          "https://res.cloudinary.com/raufsamestone/image/upload/v1616094119/foontus/utcscu3yy3la5o0o4jlt.png",
      },
    ],
  },
  {
    question: "Which one is the **Karla Tamil** ?",
    questionAnswer: "Karla Tamil",
    fontURL: 'https://www.ffonts.net/Karla-Tamil.font',
    answers: [
      {
        font: "Roboto",
        content:
          "https://res.cloudinary.com/raufsamestone/image/upload/v1616094119/foontus/yzcoikyusvwbuxz1alrb.png",
      },
      {
        font: "Karla Tamil",
        content:
          "https://res.cloudinary.com/raufsamestone/image/upload/v1616094119/foontus/utcscu3yy3la5o0o4jlt.png",
      },
    ],
  },
  {
    question: "Which one is the **Inter** ?",
    questionAnswer: "Inter",
    fontURL: 'https://fonts.google.com/specimen/Inter',
    answers: [
      {
        font: "Inter",
        content:
          "https://res.cloudinary.com/raufsamestone/image/upload/v1616323875/foontus/lp7dvrmsnus7mnucbhsr.png",
      },
      {
        font: "Arial",
        content:
          "https://res.cloudinary.com/raufsamestone/image/upload/v1616323893/foontus/lgovgbufpticsjjprhz5.png",
      },
    ],
  },
  {
    question: "Which one is the **Inter** ?",
    questionAnswer: "Inter",
    fontURL: 'https://fonts.google.com/specimen/Inter',
    answers: [
      {
        font: "Inter",
        content:
          "https://res.cloudinary.com/raufsamestone/image/upload/v1616323875/foontus/lp7dvrmsnus7mnucbhsr.png",
      },
      {
        font: "Karla Tamil",
        content:
          "https://res.cloudinary.com/raufsamestone/image/upload/v1616094119/foontus/utcscu3yy3la5o0o4jlt.png",
      },
    ],
  },
  {
    question: "Which one is the **Open Sans** ?",
    questionAnswer: "Open Sans",
    fontURL: 'https://fonts.google.com/specimen/Open+Sans',
    answers: [
      {
        font: "Inter",
        content:
          "https://res.cloudinary.com/raufsamestone/image/upload/v1616323875/foontus/lp7dvrmsnus7mnucbhsr.png",
      },
      {
        font: "Open Sans",
        content:
          "https://res.cloudinary.com/raufsamestone/image/upload/v1616324171/foontus/ickgc03d5nxmldfe7w87.png",
      },
    ],
  },
  {
    question: "Which one is the **Arial** ?",
    questionAnswer: "Arial",
    fontURL: 'https://docs.microsoft.com/en-us/typography/font-list/arial',
    answers: [
      {
        font: "Open Sans",
        content:
          "https://res.cloudinary.com/raufsamestone/image/upload/v1616324171/foontus/ickgc03d5nxmldfe7w87.png",
      },
      {
        font: "Arial",
        content:
          "https://res.cloudinary.com/raufsamestone/image/upload/v1616323893/foontus/lgovgbufpticsjjprhz5.png",
      },
    ],
  },
  {
    question: "Which one is the **Inter** ?",
    questionAnswer: "Inter",
    answers: [
      {
        font: "Inter",
        content:
          "https://res.cloudinary.com/raufsamestone/image/upload/v1616323875/foontus/lp7dvrmsnus7mnucbhsr.png",
      },
      {
        font: "Karla Tamil",
        content:
          "https://res.cloudinary.com/raufsamestone/image/upload/v1616094119/foontus/utcscu3yy3la5o0o4jlt.png",
      },
    ],
  },
  {
    question: "Which one is the **Open Sans** ?",
    questionAnswer: "Open Sans",
    answers: [
      {
        font: "Open Sans",
        content:
          "https://res.cloudinary.com/raufsamestone/image/upload/v1616324171/foontus/ickgc03d5nxmldfe7w87.png",
      },
      {
        font: "Jura",
        content:
          "https://res.cloudinary.com/raufsamestone/image/upload/v1616102525/foontus/up3ixs6qldlot08cwuaz.png",
      },
    ],
  },
  {
    question: "Which one is the **Jura** ?",
    questionAnswer: "Jura",
    answers: [
      {
        font: "Jura",
        content:
          "https://res.cloudinary.com/raufsamestone/image/upload/v1616102525/foontus/up3ixs6qldlot08cwuaz.png",
      },
      {
        font: "Oxygen",
        content:
          "https://res.cloudinary.com/raufsamestone/image/upload/v1616102553/foontus/f3mygnuwfp4y9s1inak2.png",
      },
    ],
  },
  {
    question: "Which one is the **Open Sans** ?",
    questionAnswer: "Open Sans",
    answers: [
      {
        font: "Arial",
        content:
          "https://res.cloudinary.com/raufsamestone/image/upload/v1616323893/foontus/lgovgbufpticsjjprhz5.png",
      },
      {
        font: "Open Sans",
        content:
          "https://res.cloudinary.com/raufsamestone/image/upload/v1616324171/foontus/ickgc03d5nxmldfe7w87.png",
      },
    ],
  },
  {
    question: "Which one is the **Oxygen** ?",
    questionAnswer: "Oxygen",
    answers: [
      {
        font: "Oxygen",
        content:
          "https://res.cloudinary.com/raufsamestone/image/upload/v1616102553/foontus/f3mygnuwfp4y9s1inak2.png",
      },
      {
        font: "Jura",
        content:
          "https://res.cloudinary.com/raufsamestone/image/upload/v1616102525/foontus/up3ixs6qldlot08cwuaz.png",
      },
    ],
  },
  {
    question: "Which one is the **Open Sans** ?",
    questionAnswer: "Open Sans",
    answers: [
      {
        font: "Arial",
        content:
          "https://res.cloudinary.com/raufsamestone/image/upload/v1616323893/foontus/lgovgbufpticsjjprhz5.png",
      },
      {
        font: "Open Sans",
        content:
          "https://res.cloudinary.com/raufsamestone/image/upload/v1616324171/foontus/ickgc03d5nxmldfe7w87.png",
      },
    ],
  },
  {
    question: "Which one is the **Avenir** ?",
    questionAnswer: "Avenir",
    answers: [
      {
        font: "Avenir",
        content:
          "https://res.cloudinary.com/raufsamestone/image/upload/v1616568229/foontus/x7vrmrbccqn73rpr0bal.png",
      },
      {
        font: "Open Sans",
        content:
          "https://res.cloudinary.com/raufsamestone/image/upload/v1616324171/foontus/ickgc03d5nxmldfe7w87.png",
      },
    ],
  },
  {
    question: "Which one is the **Comic Sans** ?",
    questionAnswer: "Comic Sans",
    answers: [
      {
        font: "Comic Sans",
        content:
          "https://res.cloudinary.com/raufsamestone/image/upload/v1616568303/foontus/iksewvkhuan8qn3zc4rk.png ",
      },
      {
        font: "Comic Neue ",
        content:
          "https://res.cloudinary.com/raufsamestone/image/upload/v1616568251/foontus/nlgh55ydhuzyqccc0lpl.png",
      },
    ],
  },
  {
    question: "Which one is the **Comic Neue** ?",
    questionAnswer: "Comic Neue",
    fontURL: 'https://fonts.google.com/specimen/Comic+Neue',
    answers: [
      {
        font: "Comic Sans",
        content:
          "https://res.cloudinary.com/raufsamestone/image/upload/v1616568303/foontus/iksewvkhuan8qn3zc4rk.png ",
      },
      {
        font: "Comic Neue ",
        content:
          "https://res.cloudinary.com/raufsamestone/image/upload/v1616568251/foontus/nlgh55ydhuzyqccc0lpl.png",
      },
    ],
  },
  {
    question: "Which one is the **Fira Sans** ?",
    questionAnswer: "Fira Sans",
    fontURL: 'https://fonts.google.com/specimen/Fira+Sans',
    answers: [
      {
        font: "Fira Sans",
        content:
          "https://res.cloudinary.com/raufsamestone/image/upload/v1616568322/foontus/qpxcegm4h5kjqgsueizg.png",
      },
      {
        font: "Open Sans",
        content:
          "https://res.cloudinary.com/raufsamestone/image/upload/v1616324171/foontus/ickgc03d5nxmldfe7w87.png",
      },
    ],
  },
  {
    question: "Which one is the **Helvetica** ?",
    questionAnswer: "Helvetica",
    answers: [
      {
        font: "Helvetica",
        content:
          "https://res.cloudinary.com/raufsamestone/image/upload/v1616568334/foontus/grf5lxmrpbn0npfgodju.png",
      },
      {
        font: "Roboto",
        content:
          "https://res.cloudinary.com/raufsamestone/image/upload/v1616094119/foontus/yzcoikyusvwbuxz1alrb.png",
      },
    ],
  },
  {
    question: "Which one is the **Lato** ?",
    questionAnswer: "Lato",
    fontURL: 'https://fonts.google.com/specimen/Lato',
    answers: [
      {
        font: "Lato",
        content:
          "https://res.cloudinary.com/raufsamestone/image/upload/v1616568351/foontus/ztjnlutcwcjuj3ykekv3.png",
      },
      {
        font: "Arial",
        content:
          "https://res.cloudinary.com/raufsamestone/image/upload/v1616323893/foontus/lgovgbufpticsjjprhz5.png",
      },
    ],
  },

  {
    question: "Which one is the **Lexend Deca** ?",
    questionAnswer: "Lexend Deca",
    fontURL: 'https://fonts.google.com/specimen/Lexend+Deca',
    answers: [
      {
        font: "Lexend Deca",
        content:
          "https://res.cloudinary.com/raufsamestone/image/upload/v1616568362/foontus/ethrxe0pmmyjxue2a7cq.png",
      },
      {
        font: "Helvetica",
        content:
          "https://res.cloudinary.com/raufsamestone/image/upload/v1616568334/foontus/grf5lxmrpbn0npfgodju.png",
      },
    ],
  },
  {
    question: "Which one is the **Sensation** ?",
    questionAnswer: "Sensation",
    answers: [
      {
        font: "Sensation",
        content:
          "https://res.cloudinary.com/raufsamestone/image/upload/v1616568381/foontus/eytnsgmiqimdpg2l7lj5.png",
      },
      {
        font: "Oxygen",
        content:
          "https://res.cloudinary.com/raufsamestone/image/upload/v1616102553/foontus/f3mygnuwfp4y9s1inak2.png",
      },
    ],
  },
  {
    question: "Which one is the **Montserrat** ?",
    questionAnswer: "Montserrat",
    fontURL: 'https://fonts.google.com/specimen/Montserrat',
    answers: [
      {
        font: "Montserrat",
        content:
          "https://res.cloudinary.com/raufsamestone/image/upload/v1616568395/foontus/expy6e9ktbxfphjr0k4h.png",
      },
      {
        font: "Avenir",
        content:
          "https://res.cloudinary.com/raufsamestone/image/upload/v1616568229/foontus/x7vrmrbccqn73rpr0bal.png",
      },
    ],
  },
  {
    question: "Which one is the **Raleway** ?",
    questionAnswer: "Raleway",
    fontURL: 'https://fonts.google.com/specimen/Raleway',
    answers: [
      {
        font: "Raleway",
        content:
          "https://res.cloudinary.com/raufsamestone/image/upload/v1616568410/foontus/ilrqag1futdswu48mxj9.png",
      },
      {
        font: "Open Sans",
        content:
          "https://res.cloudinary.com/raufsamestone/image/upload/v1616324171/foontus/ickgc03d5nxmldfe7w87.png",
      },
    ],
  },
  {
    question: "Which one is the **Sensation** ?",
    questionAnswer: "Sensation",
    answers: [
      {
        font: "Sensation",
        content:
          "https://res.cloudinary.com/raufsamestone/image/upload/v1616568381/foontus/eytnsgmiqimdpg2l7lj5.png",
      },
      {
        font: "Oxygen",
        content:
          "https://res.cloudinary.com/raufsamestone/image/upload/v1616102553/foontus/f3mygnuwfp4y9s1inak2.png",
      },
    ],
  },
  {
    question: "Which one is the **Helvetica** ?",
    questionAnswer: "Helvetica",
    answers: [
      {
        font: "Helvetica",
        content:
          "https://res.cloudinary.com/raufsamestone/image/upload/v1616568334/foontus/grf5lxmrpbn0npfgodju.png",
      },
      {
        font: "Roboto",
        content:
          "https://res.cloudinary.com/raufsamestone/image/upload/v1616094119/foontus/yzcoikyusvwbuxz1alrb.png",
      },
    ],
  },
];

export default fonts;
