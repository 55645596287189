import React from "react";
import PropTypes from "prop-types";
import marked from "marked";

function Question(props) {
  return (
    <div className="md:flex block items-center justify-between gap-2 mt-5 mb-5">
      <div
        className="text-lg lg:text-2xl m-0"
        dangerouslySetInnerHTML={{ __html: marked(props.content) }}
      />
      {props.fontURL && (
        <div className="flex gap-1 w-auto">
          <span className="text-xs text-gray-700 hover:underline">
            <a rel="noopener noreferrer" target="_blank" href={props.fontURL}>
              Font Source
            </a>
          </span>
          <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-3 w-3 self-center"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                  clipRule="evenodd"
                />
              </svg>
        </div>
      )}
    </div>
  );
}

Question.propTypes = {
  content: PropTypes.string.isRequired,
  fontURL: PropTypes.string,
};

export default Question;
