import React from "react";
import PropTypes from "prop-types";
import Question from "./Question";
import QuestionCount from "./QuestionCount";
import AnswerOption from "./AnswerOption";

function Quiz(props) {
  function renderAnswerOptions(key) {
    return (
      <>
        <AnswerOption
          key={key.content}
          answerContent={key.content}
          answerType={key.font}
          isCorrect={key.isCorrect}
          questionAnswer={key.questionAnswer}
          answer={props.answer}
          questionId={props.questionId}
          onAnswerSelected={props.onAnswerSelected}
          isFlipped={props.isFlipped}
          fontURL={key.fontURL}
          handleClick={props.handleClick}
        />
      </>
    );
  }

  return (
    <div key={props.questionId} className="main-section">
      <QuestionCount counter={props.questionId} total={props.questionTotal} />
      <Question content={props.question} fontURL={props.fontURL} />
      {/* //change grid for options length */}
      <ul
        key={props.questionId + 2}
        className="grid gap-4 grid-cols-2 mb-4 lg:mb-10"
      >
        {props.answerOptions.map(renderAnswerOptions)}
      </ul>
    </div>
  );
}

Quiz.propTypes = {
  answer: PropTypes.string.isRequired,
  answerOptions: PropTypes.array.isRequired,
  question: PropTypes.string.isRequired,
  questionId: PropTypes.number.isRequired,
  questionTotal: PropTypes.number.isRequired,
  onAnswerSelected: PropTypes.func.isRequired,
  questionAnswer: PropTypes.string.isRequired,
};

export default Quiz;
