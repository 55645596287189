import React, { useState } from "react";
import { useForm } from "@formspree/react";
import { FeedbackFish } from "@feedback-fish/react";

const Suggest = () => {
  const [state, handleSubmit] = useForm("xnqlanln");
  const [modal, setModal] = useState(false);

  const { name, email } = useState({});

  function handleModal(e) {
    e.preventDefault();
    if (!modal) setModal(true);
    else setModal(false);
  }
  return (
    <>
      <div className="grid md:flex gap-0 md:gap-5 ">
        <button
          onClick={handleModal}
          className="text-xs text-gray-700 hover:underline"
        >
          Suggest a new font! 🙏
        </button>
        <FeedbackFish projectId="d65ebc625fd84f">
          <button className="text-xs text-gray-700 hover:underline">
            Give feedback ⚡️
          </button>
        </FeedbackFish>
      </div>
      <h2 className="text-2xl font-bold fixed top-10 right-5">{state.name}</h2>
      <div
        className="fixed z-10 inset-0 overflow-y-auto over"
        style={{
          display: modal === false ? "none" : "",
        }}
      >
        {state.succeeded ? (
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75" />
            </div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            ></span>
            <div
              className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 sm:mt-0 sm:ml-4 text-center w-full">
                    <h2
                      className="text-lg leading-6 font-medium text-gray-900  m-auto"
                      id="modal-headline"
                    >
                      You suggested a font. Thank you! 😘
                    </h2>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  onClick={handleModal}
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-400 text-base font-medium text-white hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Back to game
                </button>
              </div>
            </div>
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div
                className="fixed inset-0 transition-opacity"
                aria-hidden="true"
              >
                <div className="absolute inset-0 bg-gray-500 opacity-75" />
              </div>
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              ></span>
              <div
                className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
              >
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-left sm:mt-0 sm:ml-4 sm:text-left w-full ">
                      <h2
                        className="text-base leading-3 font-medium text-gray-900 text-left "
                        id="modal-headline"
                      >
                        Your E-mail
                      </h2>
                      <span
                        className="text-bold text-xs text-gray-600"
                        id="modal-headline"
                      >
                        Not SPAM, just for updates. 🎉
                      </span>
                      <div className="mt-2 w-full">
                        <input
                          type="email"
                          placeholder="Optional"
                          name="Email"
                          value={email}
                          className=" mb-4 w-full rounded p-2 focus:outline-none border-2 focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                        />
                      </div>
                      <h2
                        className="text-base leading-3 font-medium text-gray-900 text-left"
                        id="modal-headline"
                      >
                        Font Name or URL
                      </h2>
                      <div className="mt-2 w-full">
                        <input
                          required
                          type="text"
                          placeholder="Roboto"
                          name="Font Name"
                          value={name}
                          className="w-full rounded p-2 focus:outline-none border-2 focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 "
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="submit"
                    disabled={state.submitting}
                    className="w-full mb-5 md:mb-0 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-900 text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Send
                  </button>
                  <button
                    onClick={handleModal}
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-400 text-base font-medium text-white hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </>
  );
};
export default Suggest;
